.backdrop_services {
  object-fit: cover;
  width: 100%;
  max-height: 50vh;
  position: fixed;
  z-index: -1;
  opacity: 1;
  background-color: black;
}

.hero-container_services {
  /* background: url('/images/img-home.jpg') center center/cover no-repeat; */
  height: 50vh;
  opacity: 1;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.3);
  object-fit: cover;
}

.subtitle_section_hero_services {
  border-top: 1px solid white;
  border-right: 1px solid white;
  border-left: 1px solid white;
  padding: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  border-top-left-radius: 5rem;
  border-top-right-radius: 5rem;
  max-width: 50vw;
  width: 50vw;
}

.Hero_Heading_services {
  color: whitesmoke;
  font-size: 3rem;
  padding-bottom: 1rem;
  padding-top: 1rem;

}

.Hero_Heading_2_services {
  color: whitesmoke;
  font-size: 1.2rem;
  align-items: center;
  text-align: center;
}

.hero-container-2_services {
  position: absolute;
  display: flex;

  flex-direction: column;
  justify-content: center;

  align-items: center;
  transition: all 0.8s ease-in-out;
  background-color: rgba(0, 0, 0, 0.6);
  border-bottom: 1px solid whitesmoke;
  border-top: 1px solid whitesmoke;
  width: 100%;

}



.Hero_Heading_3_services {
  color: whitesmoke;
  font-size: 1.2rem;
  text-align: center;
  margin-top: 5px;

}




@media screen and (max-width: 960px) {


  .hero-container_services img{
    
    object-fit: fill;

  }



  .hero-container_services {
    height: 30vh;

    
  }

  .hero-container-2_services {

    background-color: rgba(0, 0, 0, 0.2);
    object-fit: fill;
    width: 100%;


  }

  .backdrop_services {
    object-fit: contain;
    height: 100Vh;
  }

  .subtitle_section_hero_services {
    border-top: 1px solid white;
    border-right: 1px solid white;
    border-left: 1px solid white;
    padding: 2rem;
    background-color: rgba(0, 0, 0, 0.8);
    border-top-left-radius: 5rem;
    border-top-right-radius: 5rem;
    max-width: 100%;
    width: 90vw;
  }


  .Hero_Heading_2_services {

    font-size: 0.8rem;

  }

  .Hero_Heading_3_services {

    font-size: 0.8rem;

  }


  .hero-container>h1 {
    font-size: 70px;
    margin-top: 0px;
  }

  .Hero_Heading {
    color: #fff;
    font-size: 3rem;
    text-align: center;
  }

  .Hero_Heading_2 {
    color: #fff;
    font-size: 1.5rem;
    text-align: center;
    width: 100%;
    padding: 1rem;
  }

  .Hero_Heading_3 {
    color: #fff;
    font-size: 1rem;
    text-align: center;
  }

  .hero-container {
    height: 92vh;
  }

  .hero-container-2:hover {
    background-color: rgba(0, 0, 0, 0)
  }

  .hero-btns {
    display: flex;
    flex-direction: column;


  }

  .logo_hero {

    max-width: 20%;

  }


}

@media screen and (max-height: 500px) {
  .hero-btns {
    display: flex;
    flex-direction: row;


  }

  .logo_hero {
    padding-top: 10px;
    max-width: 10%;

  }

}