.card_style {
    height: "200px";
    width: "200px";
    
    text-align: "center";
    background: "#52C0F5";
    color: "#FFF";
    font-family: "sans-serif";
    font-size: "12px";
    text-transform: "uppercase";
    border-radius: "10px";
    box-sizing: "border-box";
    
}

.test {
    color: white;
    background-color: aqua;
    padding: 100px;
    border-radius: 20px;
}



.notifications_event_main {
    
    background: url('./eventbackdrop.jpg');
    object-fit: contain;
    opacity: 1;
    align-items: center;
    justify-content: center;
    border-top: 1px solid white;
    
}

.card {
    border-radius: 20px;
    width: 60vw;
    height: 40vh;
    border-bottom: 10px solid #918e9b;

    color: whitesmoke;
    background-size: cover;
    background: linear-gradient(0deg, #000000, #808080);
    scale: 1;
    transition: all 0.2s ease-in-out;
    cursor: pointer;
    display: flex;
    flex-direction: column;
    padding: 0;
}

.Title_section {
    background-image: url('./card_backdrop.jpg');
    padding: 2vh;
    background-size: cover;
    
    justify-self: center;
    justify-content: center;
    border-bottom-right-radius: 30px;
    font-size: 3rem;
    color: whitesmoke;
    
    border-bottom: 1.15px solid white;
    border-right: 1.15px solid white;
    width: fit-content;
    
    
}

.card:hover {
    scale: 1.1;
    transition: all 0.5s ease-in-out;
    cursor: pointer;
}

.card_item_main {


align-self: center;
width: 100%;



}

.event_date {
    text-align: left;
    bottom: 0;
    position: relative;
    vertical-align: bottom;

    font-size: 2rem;
    
}

.title_text {
    font-size: 3rem;
}

.date_title {
    font-size: 1.5rem;
    text-align: left;
    font-weight: 900;
    padding-left: 1vw;
    padding-bottom: 1vh;

}

.event_title {
    text-align: center;
    
    /* Created with https://www.css-gradient.com */
    background: #918e9b;
    background: -webkit-linear-gradient(top, #918e9b, #FFFFFF);
    background: -moz-linear-gradient(top, #918e9b, #FFFFFF);
    background: linear-gradient(to bottom, #918e9b, #FFFFFF);
    width: 100%;
    color: black;
    float: left;
    
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;
    margin-bottom: 30px;
}

.event_description {
    font-size: 2rem;
    padding-left: 2vw;
    padding-right: 2vw;
}

.event_description_text {

    text-align: center;

}

.event_date_address {
    font-size: 1.4rem;
    bottom: 0;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    vertical-align: bottom;
    padding-left: 20px;
    padding-top: 30px;
}
.event_date_text{
    position: absolute;
    bottom: 1vh;
}
.event_judge_text{
    
    position: absolute;
    
    bottom: 4vh;
}



@media screen and (max-width: 960px) {
    .event_description_text {

        text-align: center;
        font-size: 0.9rem;
        padding: 0;
        margin: 0;
    }

    .Title_section{
        font-size: 2rem;
        border: 1px solid white;
        border-bottom: 5px solid white;
        border-right: 5px solid white;
        
    }

    .title_text {
        font-size: 2rem;
        
    }

    .event_date {
        font-size: 0.8rem;
    }

    .event_judge_text{
        font-size: 0.8rem;
        position: absolute;
        bottom: 5vh;
    }
    .date_title{
        font-size: 1rem;
    }
    .event_date_text{
        font-size: 0.8rem;
        position: absolute;
        bottom: 1vh;
        
    }

    .card {
        width: 60vw;
        

    }


}